import farmSeasonFinalize from '@actions/FarmSeasonActions/FarmSeasonFinalize';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import useCanFinalizeSeason from '@hooks/useCanFinalizeSeason';
import IconTractor from '@icons/icon-tractor.svg';
import { History } from '@mui/icons-material';
import { Avatar, ButtonBase, Divider, Paper, SvgIcon, Typography } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { ModalWarning, useModal } from '@soil-capital/ui-kit';
import { isNullOrEmpty } from '@utils/stringHelper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIsReadOnlyUser } from '@hooks/usePermissions';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import useAdminPanelStyle from './AdminPanelView.style';
import ModalFinalizeSeasonView from './ModalFinalizeSeason/ModalFinalizeSeasonView';

const AdminPanelView = (): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const isOperationPath = location.pathname.includes('operation');
    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason.installation?.onboarding,
    );
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const isHistoryDone = !!(
        onboarding?.pastureHistory?.done &&
        onboarding.coverCropHistory?.done &&
        onboarding.tillagePracticeHistory?.done &&
        onboarding.organicFertilizationHistory?.done
    );
    const isHistoryPath = location.pathname.includes('practice-history');
    const { classes } = useAdminPanelStyle();
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const selectedUser = useSelector((state: HomePageStateT) => state.auth.selectedUser);
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const isReadOnlyUser = useIsReadOnlyUser();

    const canFinalizeSeason = useCanFinalizeSeason();
    const modalFinalizeSeason = useModal();

    const handleFinalize = () => {
        if (farm && farmSeason)
            modalFinalizeSeason.openModal(async () => dispatch(farmSeasonFinalize(farm.id, farmSeason.id)));
    };

    const controllerModalNoOpenOperation = useModal();
    const controllerModalNoOpenHistory = useModal();

    const handleOpenOperations = () => {
        // prevent open operations page if initial setup (installation) is not finalized.
        if (!farmSeason?.installation_validated_at) {
            controllerModalNoOpenOperation.openModal();
        } else {
            navigate('/operations');
        }
    };
    const handleOpenPracticeHistory = () => {
        // prevent open operations page if initial setup (installation) is not finalized.
        if (!farmSeason?.installation_validated_at) {
            controllerModalNoOpenHistory.openModal();
        } else {
            navigate('/practice-history');
        }
    };

    const getAuditInfoMessage = (): JSX.Element | null => {
        if (farmSeason && !isNullOrEmpty(farmSeason.audit_status)) {
            return (
                <Typography className={classes.auditMsg} color="primary" variant="body1">
                    {t(`pages.homepage.sidebar.admin-panel.audit-status-${farmSeason.audit_status}`)}
                </Typography>
            );
        }

        return null;
    };

    return (
        <>
            <Paper
                className={`${classes.container} simulationActionOff simulationAction`}
                elevation={0}
                variant="elevation"
            >
                <Typography color="primary" variant="h5">
                    {t('pages.homepage.sidebar.admin-panel.working-as')}
                </Typography>
                <ButtonBase className={classes.profile} onClick={() => navigate('/search')}>
                    <Avatar className={classes.avatar} />
                    <Typography className={classes.profileName} color="primary" variant="h6">
                        {selectedUser?.fullName}
                    </Typography>
                </ButtonBase>
                {!isReadOnlyUser && (
                    <>
                        <Divider />
                        {isBaselineSeason && (
                            <ButtonBase
                                className={`${classes.task} ${isHistoryPath ? classes.taskActive : ''}`}
                                onClick={handleOpenPracticeHistory}
                            >
                                <div className={classes.iconContainer}>
                                    <SvgIcon className={classes.icon} component={History} viewBox="2 6 21 13" />
                                </div>
                                <Typography className={classes.taskText} color="primary" variant="h6">
                                    {t('pages.homepage.sidebar.admin-panel.practice-history')}
                                </Typography>
                            </ButtonBase>
                        )}
                        <ButtonBase
                            className={`${classes.task} ${isOperationPath ? classes.taskActive : ''}`}
                            onClick={handleOpenOperations}
                        >
                            <div className={classes.iconContainer}>
                                <SvgIcon className={classes.icon} component={IconTractor} viewBox="0 0 21 18" />
                            </div>
                            <Typography className={classes.taskText} color="primary" variant="h6">
                                {t('pages.homepage.sidebar.admin-panel.operation')}
                            </Typography>
                        </ButtonBase>
                        {canFinalizeSeason &&
                            (isBaselineSeason ? isHistoryDone : true) &&
                            !farmSeason?.operations_validated_by && (
                                <LoadingButton
                                    className={classes.finalizeSeasonBtn}
                                    disabled={
                                        !!farmSeason?.operations_validated_by ||
                                        farmSeason?.audit_status === 'in_progress' ||
                                        farmSeason?.audit_status === 'done'
                                    }
                                    loading={false}
                                    padding="12px"
                                    text={
                                        farmSeason?.operations_validated_by
                                            ? t('pages.homepage.sidebar.admin-panel.finalized-season')
                                            : t('pages.homepage.sidebar.admin-panel.finalize-season')
                                    }
                                    fullWidth
                                    onClick={handleFinalize}
                                />
                            )}

                        {getAuditInfoMessage()}

                        <ModalFinalizeSeasonView modalController={modalFinalizeSeason} />
                    </>
                )}
            </Paper>

            <ModalWarning
                controller={controllerModalNoOpenOperation}
                description={t('modal.warning.installation-should-be-done-to-access-operations')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
            <ModalWarning
                controller={controllerModalNoOpenHistory}
                description={t('modal.warning.installation-should-be-done-to-access-practice-history')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default AdminPanelView;
